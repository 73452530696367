.lightGreyBanner {
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 50px;
}

.tealBanner {
    background: linear-gradient(to right, #44a9b5, #165e74);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin-top: -50px;
    padding-top: 60px;
}

.whiteBanner {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
}

.darkGreyBanner {
    background-color: #36393b;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 40px;
}

.tealText {
    margin: 40px;
    color: #227c97;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
}

.blackText {
    margin: 0px;
    margin-bottom: 30px;
    margin-inline: 50px;
    color: black;
    font-size: 15px;
    font-weight: bold;
    text-align: center;
}

.smallBlackText {
    margin: 0px;
    margin-block: 12px;
    color: black;
    font-size: 13px;
    font-weight: bold;
    text-align: center;
}

.whiteText {
    margin: 0px;
    margin-block: 12px;
    color: white;
    font-size: 13px;
    font-weight: 500;
    text-align: center;
}

.verticalDivider {
    background-color: #44a9b5;
    width: 1.5px;
    height: 75px;
    margin-inline: 20px;
}

.downloadRowContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin-inline: 50px;
}

.appRowContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-inline: 20px;
    margin-top: -10px;
}

.appScreenshotContainer {
    width: 300px;
    margin: 12px;
    padding-top: 10px;
    padding-bottom: 3px;
    border-radius: 10px;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
}

/* Images */
.appIcon {
    width: 100px;
    object-fit: contain;
    z-index: 999;
}

.riderLogo {
    width: 250px;
    object-fit: contain;
}

.groupScreenshot {
    width: 600px;
    object-fit: contain;
    margin-top: 25px;
}

.downloadIcon {
    width: 200px;
    object-fit: contain;
    margin-inline: 10px;
    margin-block: 10px;
    cursor: pointer;
}

.qrcode {
    width: 100px;
    object-fit: contain;
    margin-inline: 10px;
    border-radius: 5px;
}

.appScreenshot {
    width: 100%;
    object-fit: contain;
}

.footerImage {
    width: 150px;
    object-fit: contain;
    margin-top: 10px;
    cursor: pointer;
}

/* Responsive UI */
@media (max-width: 825px) {
    .downloadRowContainer {
        margin-top: -20px;
    }
}

@media (max-width: 700px) {
    .tealBanner {
        margin-top: -40px;
    }

    .tealText {
        font-size: 25px;
    }

    .appIcon {
        width: 80px;
    }

    .riderLogo {
        width: 220px;
        margin-top: -10px;
    }

    .groupScreenshot {
        width: 500px;
    }
}

@media (max-width: 500px) {
    .groupScreenshot {
        width: 400px;
    }
}

@media (max-width: 450px) {
    .groupScreenshot {
        width: 300px;
    }
}

@media (max-width: 400px) {
    .groupScreenshot {
        width: 280px;
    }

    .appScreenshotContainer {
        width: 250px;
    }
}